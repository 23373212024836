/* body {
  margin: 0;
  font-family: 'Nunito', sans-serif;
}

body * {
  transition: all 0.300s ease-in-out;
} */

.container { 
  background-color: #fff;
  width: 100%;
  max-width: 1500px;
  margin: 0 auto;
  -webkit-box-shadow: 0px 10px 54px -11px rgba(0,0,0,0.75);
	-moz-box-shadow: 0px 10px 54px -11px rgba(0,0,0,0.75);
	box-shadow: 0px 10px 54px -11px rgba(0,0,0,0.75);
}

/*------------- NAVIGATION BAR -------------------*/


#nav {
	position: fixed;
	z-index: 99;
	width: 100%;
	max-width: 1500px;
	margin: 0 auto;  
	padding: 12px 0;
	display: grid;
	text-align : center;
	grid-template-columns: 1fr; 
	 background: #fff;
	  -webkit-box-shadow: 0px 10px 5px -11px rgba(0,0,0,0.50);
	-moz-box-shadow: 0px 10px 5px -11px rgba(0,0,0,0.50);
	box-shadow: 0px 10px 5px -11px rgba(0,0,0,0.50); 
}

.nav-container {
	width : 80% ;
	display: grid;
	grid-template-columns: 20% 50% 30%; 
	margin : 0 auto;
}

nav ul {
  width: 100%;
  display: grid;
  list-style: none;
  align-items: center;
  padding: 0;
  margin: 0;
} 

nav ul:first-of-type{
  grid-template-columns: 1fr;
}

nav ul:first-of-type li{
  font-size: 1.4rem;
  font-weight: bold;
  text-align : left; 
}

nav ul:first-of-type li span {
  font-weight: 500;
  color: #444;
}

nav ul:last-of-type{
  grid-template-columns: 70% 30%;
}

nav ul:last-of-type li{
  text-align : right; 
}

nav ul li a{
  color:#555;
  text-decoration: none; 
  font-weight: 600;
  display: block;
  font-size : 14px;
  text-align : center;
}

.main-menu {
	grid-template-columns: repeat(6, 1fr);
}



/*------------- DROPDOWN MENU -------------------*/
.dropdown {
  float: left;
  overflow: hidden;
}

.dropdown .dropbtn {
	cursor : pointer;
  font-size: 14px;  
  border: none;
  outline: none;
  color: #555555;
  padding: 0px 10px;
  background-color: inherit;
  /*font: inherit; */
  margin: 0;
}

.dropdown-content {
	margin-top : 0px ;
  display: none;
  position: absolute;
  background-color: none;
  width: 100%;
  left: 0;
 
  z-index: 1;
}

.dropdown-main-content {
	width: 100%;
	box-shadow: 0px 8px 12px 0px rgba(0,0,0,0.2);
	background-color: white;
	text-align : left;
}

.dropdown-content .header {
  background: red;
  padding: 16px;
  color: white;
}

.dropdown:hover .dropdown-content {
  display: block;
}



/*------------- HEADER -------------------*/

header div.head {
  position: relative;
  z-index: 2;
  display: block;
  width: 100%;
  grid-template-columns: repeat(2,1fr);
  margin: 0 auto;
  padding-top: 10%;
  align-items: center;
  text-align :center ;
}

header div.head  {
  color: #222;
}

header div.head h1 {
  font-size: 5rem;
  font-weight: bold;
  text-transform: uppercase;
  padding-top: 0px;
  margin: 0;
  letter-spacing: 4px;
  border-right: 2px solid #111;
}

header div.head p {
  padding-left: 0px;
}

.contact {
 background: rgb(0, 99, 248);
 display: block;
 color: #fff;
 font-weight: bold;
 width: 50%;
 margin: 0 auto;
 text-align: center;
 padding: 12px;
 border-radius: 10px;
 text-decoration: none; 
 -webkit-box-shadow: 0px 10px 54px -11px rgba(0,0,0,0.75);
 -moz-box-shadow: 0px 10px 54px -11px rgba(0,0,0,0.75);
 box-shadow: 0px 10px 54px -11px rgba(0,0,0,0.75);
}


/*------------- MAIN SECTION -------------------*/
main {
	position: relative;
	z-index: 3;
	overflow: hidden;
}

main section {
	width: 100%;
	padding: 50px 0;
	margin: 0px auto 0px auto;
	display: block;
	grid-template-columns: 2fr 3fr;
	align-items: center;
	text-align : center;
}

main section div {
	width: 80%;
	text-align : center;
	margin : 0 auto;
  /*position: relative; */
}

main section h2 {
  font-size: 1.5rem;
  font-weight : 900 ;
  color: #222;
  /*
  text-transform: none;
	line-height: 4.2rem; 
	padding-right: 10px;
	border-right: 3px solid #ddd; */
}


main section div p {
  padding-left: 0px;
}

.wrapper-strategy {
	width : 80%;
  display: grid;
  grid-template-columns: 45% 45%;
  grid-gap: 10%;
  /*background-color: #fff; */
  justify-items: center;
  color: #444;
}

.box {
  background-color: #444;
  color: #fff;
  border-radius: 5px;
  padding: 20px;
  font-size: 150%;
}

/* ---------------- SECTION DEMO ---------------------*/
.grid-view-demo {
	display : grid;
	grid-template-columns: 1fr 1fr;
	height: auto;
}

.demo-title {
	margin : 0px;
	font-weight : 900;
	color : #333333 ;
}

.text-align-demo-title {
	text-align : left;
}

/* ---------------- SECTION TESTIMONY ---------------------*/
.testimony {
	background-color : #262261 ;
	color : white ;
}

.text-client-title {
	font-weight : 900;
	color : #333333 ;
}

/* ---------------- SECTION CLIENT & PARTNER ---------------------*/
.box-client {
	display : grid;
	height: auto;
	grid-template-columns: repeat(5,1fr);
}

/* ---------------- SECTION TOUR ---------------------*/
.box-tour {
	width : 50% ;
	background-color : #f5f5f5 ;
	border-radius : 10px ;
	padding : 20px ;
}
 
.box-tour p {
	color : #333333 ;
}

/* ---------------- SECTION TOUR ---------------------*/
.box-mobile {
	width : 70% ;
	display : grid;
	height : auto;
	grid-template-columns: 1fr 1.5fr;
}

.box-mobile-right {
	text-align : left ;
}

/* ---------------- SECTION PROMO ---------------------*/
.box-promo {
	width : 70% ;
	display : grid;
	height : auto;
	grid-template-columns: repeat(3,1fr);
}

.box-gray-radius {
	background-color : #e5e5e5 ;
	border-radius : 10px ;
	padding : 10px ;
	min-height : 80px ;
}

.box-red-gradient {
	background: rgb(244,105,124);
	background: linear-gradient(180deg, rgba(244,105,124,1) 48%, rgba(227,126,164,1) 100%);
	border-radius : 10px ;
	padding : 10px ;
	min-height : 80px ;
	color : #ffffff ;
}

/* ---------------- SECTION BOTTOM OF MAIN ---------------------*/
.bottom-main-title {
	font-size : 25px ;
	letter-spacing: 2px;
}




/*------------- GALLERY -------------------*/

.gallery{
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  height: 820px;
  width: 95%;
  margin: 180px auto 0px auto;
  -webkit-box-shadow: 0px 10px 20px -11px rgba(0,0,0,0.9);
 -moz-box-shadow: 0px 10px 20px -11px rgba(0,0,0,0.9);
 box-shadow: 0px 10px 20px -11px rgba(0,0,0,0.9);
}

.gallery div {
  width: 100%;
  height: 100%;
  position: relative;
   overflow: hidden;
   filter: grayscale(83%);
}

/* images are not compressed, remember to compress them before deployment */
.gallery div.gallery-item-one {
  /* background: url("./assets/images/400.png"); */
  background-size: 160%;
 grid-column-end: span 2;
 grid-row-end: span 2;
}

.gallery div.gallery-item-two {
  /* background: url("./assets/images/400.png"); */
  background-size: 160%;
}
.gallery div.gallery-item-three {
  /* background: url("./assets/images/400.png"); */
  background-size: 160%;
}

.gallery div.gallery-item-four {
  /* background: url("./assets/images/400.png"); */
  background-size: 160%;
}
.gallery div.gallery-item-five {
  /* background: url("./assets/images/400.png"); */
  background-size: 160%;
}
.gallery div.gallery-item-six {
  /* background: url("./assets/images/400.png"); */
  background-size: 160%;
}
.gallery div.gallery-item-one:hover, .gallery div.gallery-item-two:hover, .gallery div.gallery-item-three:hover, .gallery div.gallery-item-four:hover, .gallery div.gallery-item-five:hover, .gallery div.gallery-item-six:hover{
  background-size: 100%;
  filter: grayscale(0%);
}

 /*------------- SERVICES -------------------*/

div.services {
	display: grid;
	height: auto;
	grid-template-columns: repeat(5,1fr);
	padding: 0px;
}
 
.services-div {
  text-align: center;
  background: #fff;
  width: 90%;
  border-radius: 10px;
  margin: 0px auto 20px auto;
  
  -webkit-box-shadow: 0px 5px 15px -9px rgba(0,0,0,0.75);
  -moz-box-shadow: 0px 5px 15px -9px rgba(0,0,0,0.75);
  box-shadow: 0px 5px 15px -9px rgba(0,0,0,0.75);
}

.services p.service-icon {
  font-size: 3.4rem;
  padding: 30px 0px 30px 90px;
  margin: 0px 0px 30px 0px;
  text-align: left;
  color: rgb(0, 99, 248);
  filter: grayscale(0%);
  border-bottom: 2px solid rgb(0, 99, 248);
}

.services p.service-title{
  font-size: 3.1rem;
  padding: 0;
  margin: 0;
}

.services p:last-of-type{
  padding: 0px 10px 20px 10px; 
  text-align: left;
}



div.recommended {
	display: grid;
	height: auto;
	grid-template-columns: repeat(4,1fr);
	padding: 0px;
}

.recommended div {
	text-align: center;
	background: #fff;
	width: 80%;
	border-radius: 10px;
	padding : 10px 0px ;
	margin: 0px auto 20px auto;
  
	-webkit-box-shadow: 0px 5px 15px -9px rgba(0,0,0,0.75);
	-moz-box-shadow: 0px 5px 15px -9px rgba(0,0,0,0.75);
	box-shadow: 0px 5px 15px -9px rgba(0,0,0,0.75);
}

.recommended p.service-icon {
	font-size: 3.4rem;
	padding: 30px 0px 30px 90px;
	margin: 0px 0px 30px 0px;
	text-align: left;
	color: rgb(0, 99, 248);
	filter: grayscale(0%);
	border-bottom: 2px solid rgb(0, 99, 248);
}

.recommended p.service-title{
	font-size: 3.1rem;
	padding: 0;
	margin: 0;
}

.recommended p:last-of-type{
	padding: 0px 10px 20px 10px; 
	text-align: left;
}

/* ---------------- PAGE ABOUT ---------------------*/
.box-about {
	margin : 0 auto;
	width : 70%;
}

.box-about-2 {
	display: grid;
	height: auto;
	padding : 0px ;
	margin : 0 auto;
	width : 70%;
	grid-template-columns: 46% 8% 46%;
}

/* ---------------- PAGE PRICE ---------------------*/
.box-price {
	display: grid;
	height: auto;
	padding : 0px ;
	margin : 0 auto;
	width : 80%;
	grid-template-columns: 75% 25%;
}

.box-price-left-side {
	text-align : left ;
}

/* ---------------- PAGE CONTACT ---------------------*/
.box-contact {
	margin : 0 auto;
	width : 70%;
}

.box-contact-address {
	display: grid;
	height: auto;
	padding : 0px ;
	margin : 0 auto;
	width : 70%;
	text-align : left ;
	grid-template-columns: 47% 6% 47%;
}


 /*------------- FORM -------------------*/

main section form {
  padding: 0px;
  margin: 0;
}

main section form div {
	border:1px solid #eee;
	padding: 10px;
	border-radius: 10px;
	font-size: 1.1rem;
	display: grid;
	margin-bottom: 10px;
	grid-template-columns: 1fr 3fr;
	align-items: center;
}

main section form div:last-of-type {
  display: grid;
  grid-template-columns: 3fr 1fr;
} 

main section form div:last-of-type input {
  background: rgb(0, 99, 248);
  padding: 10px;
  color: #fff;
  border-radius: 10px;
  cursor: pointer;
  -webkit-box-shadow: 0px 10px 10px -9px rgba(0,0,0,0.75);
  -moz-box-shadow: 0px 10px 10px -9px rgba(0,0,0,0.75);
  box-shadow: 0px 10px 10px -9px rgba(0,0,0,0.75);
}

main section form div label {
  background: #444;
  color: #fff;
  font-weight: bold;
  font-size: 1rem;
  padding: 6px;
  text-align: center;
  margin-right: 10px;
  border-radius:10px;
  -webkit-box-shadow: 0px 10px 10px -9px rgba(0,0,0,0.75);
  -moz-box-shadow: 0px 10px 10px -9px rgba(0,0,0,0.75);
  box-shadow: 0px 10px 10px -9px rgba(0,0,0,0.75);
}

main section form div input {
  background: none;
  border: none;
  font-size: 1rem;
}

main section form div textarea {
  font-family: Raleway;
  border: none;
  background: none;
  font-size: 1rem;
  resize: vertical;
}

.error {
  color: rgb(255, 57, 57);
  font-size: 1.3rem;
}

footer {
  background: #262261 /*rgb(0, 99, 248) */;
  color: #fff;
  padding: 20px;
  margin-top: 0px;
  display: grid;
  grid-template-columns:1fr;
  align-items: center;
  /*text-align: center; */
}

.box-footer {
	margin : 0 auto ;
	width : 100% ;
	display: grid;
	height: auto;
	grid-template-columns: 0.5fr 1fr 1fr 1fr 1fr 1fr;
	padding: 0px;
}

.box-footer-root {
	margin : 0 auto ;
	width : 80% ;
}

.footer-span-title {
	color : #bebdcf ;
	font-weight : normal;
}

.footer-icon {
		text-align : left ;
	}


footer ul {
  list-style: none;
  padding: 0;
  margin: 0; 
  /*
  display: grid;
  grid-template-columns: 1fr 1fr 1fr; */
  justify-items: left; 
  text-align : left ;
}

footer ul li a {
  color: #f0f0f4;
  font-style : normal ;
  text-decoration: none;
  font-size : 14px ;
  letter-spacing: 1.5px;
}



@media (max-width:1310px) and (min-width:1100px) { 
   header div.head {
      width: 75%;
      padding-top: 190px;
   }
	
   header div.head h1 {
      font-size: 4rem;  
   }
    
   main section {
      width: 100%;
      padding: 90px 0;
      margin: 0px auto 50px auto;
      display: grid;
      grid-template-columns: 1fr;      
   }
	 
   main section div p {
      padding: 0px 0px;
   }
    
   .services p.service-icon {
      font-size: 2.4rem;
      padding: 10px 30px;
      margin: 0px 0px 30px 0px;      
   }
	
   .services p.service-title{
      font-size: 2.1rem;
      padding: 0;
      margin: 0;
   }
    
   .services p:last-of-type{
      padding: 0px 20px; 
      text-align: left;
   }

   .gallery{
      height: 600px;
   }
}


@media (max-width:1099px) and (min-width:850px) {
	header {
		/* height: 350px; */
		background-position: bottom;
	}

	header div.head {
		width: 75%;
		padding-top: 400px;
		grid-template-columns: 1fr;   
	}
	
	header div.head h1 {
		font-size: 4rem;  
	}
	
	main section:first-of-type{
		margin-top: 400px;
	}

	main section {
		width: 100%;
		padding: 90px 0;
		margin: 0px auto 50px auto;
		display: grid;
		grid-template-columns: 1fr;  
	}
  
	main section div p {
		padding: 0px 0px;
	}
  
	.services p.service-icon {
		font-size: 2.4rem;
		padding: 10px 30px;
		margin: 0px 0px 30px 0px;   
	}
	
	.services p.service-title{
		font-size: 2.1rem;
		padding: 0;
		margin: 0;
	}
  
	.services p:last-of-type{
		padding: 0px 20px; 
		text-align: left;
	}

	.gallery{
		height: 420px;
	}
}


@media (max-width:849px) and (min-width:600px) {
	/* header {
		height: 440px;
	} */
  
	header div.head {
		width: 75%;
		padding-top: 120px;
		grid-template-columns: 1fr;
	}
	
	header div.head h1 {
		font-size: 3rem;   
	}

	main section {
		width: 100%;
		padding: 90px 0;
		margin: 0px auto 50px auto;
		display: grid;
		grid-template-columns: 1fr;   
	}
	
	main section div p {
		padding: 0px 0px;
	}
  
	div.services {
		display: grid;
		grid-template-columns: 1fr;
	}
	
   div.services div {
		width: 70%;
	}

	.services p.service-icon {
		font-size: 2.4rem;
		padding: 10px 30px;
		margin: 0px 0px 30px 0px; 
	}
	
	.services p.service-title{
		font-size: 2.1rem;
		padding: 0;
		margin: 0;
	}
  
	.services p:last-of-type{
		padding: 0px 20px; 
		text-align: left;
	}

	.gallery{
		height: 400px;
	}
}


@media (max-width:599px) and (min-width:0px) {
	
	nav {
		text-align: center;
		display: grid;
		grid-template-columns: 1fr;
	}
  
	nav ul {
		margin-bottom: 10px;   
	}
	
	nav ul:first-of-type {
		border-bottom:1px solid #111;
		padding-bottom: 10px; 
	}
	
	nav ul:first-of-type li{
		text-align: center;
		width: 100%;
		padding-top: 0px;  
	}
	
	nav ul:last-of-type li{
		text-align: center;
		width: 100%;
		padding-top: 10px;  
	}
	
	.nav-container {
		/*text-align : center; */
		width : 100% ;
		display: grid;
		grid-template-columns: 1fr; 
		margin : 0 auto;
	}
  
	.main-menu {
		grid-template-columns: repeat(4, 1fr);
	}
	
	/* HEADER */
  
	/* header {
		background-size: 160%;
		position: relative;  
	} */
  
	header div.head {
		width: 75%;
		grid-template-columns: 1fr;
		padding-top: 160px;   
	}
	
	header div.head h1 {
		font-size: 2.3rem;
		border: none;
	}
  
	/* header div.head p {
		padding-left: 0px;
	} */
  
	main section:first-of-type{
		margin-top: 250px;
	}

	main section {
		width: 100%;
		padding: 10px 0;
		margin: 50px auto 0px auto;
		display: grid;
		grid-template-columns: 1fr;  
	}
	
	main section div p {
		padding: 0px 0px;
		text-align : justify ;
	}
	
	/* STRATEGY*/
	.wrapper-strategy {
		width : 90%;
	  display: grid;
	  grid-template-columns: 90%;
	  grid-gap: 20px;
	  /*background-color: #fff; */
	  justify-items: center;
	  color: #444;
	}
  
	/*------------- SERVICES -------------------*/
	div.services {
		display: grid;
		grid-template-columns: 1fr;
	}
	
	div.services div {
		width: 100%;
	}

	.services p.service-icon {
		font-size: 2.4rem;
		padding: 10px 30px;
		margin: 0px 0px 30px 0px;  
	}
	
	.services p.service-title{
		font-size: 2.1rem;
		padding: 0;
		margin: 0;
	}
  
	.services p:last-of-type{
		padding: 0px 20px; 
		text-align: left;
	}
	
	/*------------- RECOMMENDED -------------------*/
	div.recommended {
		display: grid;
		grid-template-columns: 1fr;
	}
	
	div.recommended div {
		width: 95%;
	}

	/* ---------------- SECTION DEMO ---------------------*/
	.grid-view-demo {
		display : grid;
		grid-template-columns: 1fr;
		height: auto;
	}

	/*------------- GALLERY -------------------*/
	.gallery{
		grid-template-columns: 1fr;
		height: auto;
		margin: 30px auto 0px auto;
	}
	
	.gallery div {
		height: 200px;
	}

	.gallery div.gallery-item-one {
		grid-column-end: span 1;
		grid-row-end: span 1;
	}

	main section form div label {
		margin-right:10px;
		border-radius: 5px; 
	}
  
	main section form div:last-of-type input {
		padding: 10px;
		border-radius: 5px;
	}
  
	footer {
		padding: 20px 0;
		text-align: center;
		grid-template-columns:1fr;
		font-size: 12px;
	}
	
	footer ul {
	  list-style: none;
	  padding: 0;
	  margin: 0; 
	  /*
	  display: grid;
	  grid-template-columns: 1fr 1fr 1fr; */
	  justify-items: center; 
	  text-align : center ;
	  margin-bottom : 20px ;
	}

	footer ul li a {
		font-size: 12px;
	}
	
	.box-footer {
		margin : 0 auto ;
		width : 100% ;
		display: grid;
		height: auto;
		grid-template-columns: 1fr;
		padding: 0px;
	}

	.box-footer-root {
		margin : 0 auto ;
		width : 90% ;
	}

	.footer-span-title {
		color : #bebdcf ;
		font-weight : normal;
	}
	
	.footer-icon {
		text-align : center ;
	}
	

}
